import * as React from "react";
import { Component } from "react";
import { Text, View, SafeAreaView, ScrollView } from "react-native";

import Fire from "../../utils/Firebase";

import { FORAGE_ENV } from "@env";

import ForageTitle from "../ForageTitle";

export default class HomeScreen extends Component {
  render() {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <View>
          <ForageTitle />
        </View>

        <Text style={{ fontSize: 20, fontWeight: "bold" }}>Problem </Text>

        <Text>
          • Excessive amount of time to check every food delivery service to
          compare prices/time.
        </Text>
        <Text>
          • Significant variance on costs/fee/price on identical orders from
          different delivery services.
        </Text>
        <Text>
          • Lack of knowledge. Most people are unaware of the degree of the
          price variances.
        </Text>

        <Text style={{ fontSize: 20, fontWeight: "bold" }}>Solution</Text>

        <Text>
          • Forage guarantees savings average of 25%-50% on identical orders.
        </Text>
        <Text>
          • Use Forage’s platform to search for food and get an estimates.
        </Text>
        <Text>• Forage displays which promotions are being offered.</Text>
        <Text>
          • Forage displays price breakdown to save you time and money.
        </Text>
      </View>
    );
  }
}
