import * as React from 'react';
import { Component } from "react";
import { StyleSheet, Text, View, Button, Alert } from 'react-native';
import { createStackNavigator } from '@react-navigation/stack';

import styles from '../../utils/styles';

import ForageTitle from '../ForageTitle'
import PromosHomeScreen from './PromosHomeScreen';
import PromosListScreen from './PromosListScreen';



export default class PromosNav extends Component  {
    render() {
      const Stack = createStackNavigator();
      return (
        <Stack.Navigator initialRouteName="Promos Home" screenOptions={{ title: "Promos", headerShown: false,  headerBackTitle:''}}>
          <Stack.Screen name="Promos Home" title='' component={PromosHomeScreen}  />
          <Stack.Screen name="Promos List" title='' component={PromosListScreen}  />
        </Stack.Navigator>
      );
    }
  }