import {  
    Dimensions,  
    StyleSheet,
    PixelRatio,
    Platform,
} from 'react-native';
import styleConst from './styleConst';

const foragePurple = styleConst.foragePurple;
const forageGreen = styleConst.forageGreen;
const forageGray = styleConst.forageGray;
const forageBlack = styleConst.forageBlack;


const width = Dimensions.get('window').width
const height = Dimensions.get('window').height

const styles = StyleSheet.create({
    screenWidth: width,
    screenHight: height,
    
    foragePurple: {color: foragePurple},
    forageGreen: {color: forageGreen},
    forageGray: {color: forageGray},
    forageBlack: {color: forageBlack},

    // Forage Title Styles
    forageTitle: {
		flex: 1,
		// height: 80,
		alignItems: 'center', 
		justifyContent: 'center',
    }, 
    forageTitleText: {
        color: foragePurple,
        fontSize: 84,
        fontFamily: "FredokaOne",
    },
    forageSubtext: {
        color: foragePurple,
        // fontWeight: 'bold',  
        fontSize: 32,
        fontFamily: "FredokaOne",
    },
    forageHeaderViewStyle: {
        height: 64,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderTopWidth: 0,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderBottomWidth: 0.25,
        borderBottomColor: forageGray,
        backgroundColor: 'white',
    },
    forageHeaderText: {
        color: foragePurple,
        fontSize: 22,   
        fontFamily: "FredokaOne",
    },

    // ScrollView Styles
    SafeAreaViewStyle: {
        // flexGrow: 1,
        flex: 1,
    },
    ScrollViewStyle: {
        // flex: 1, // NOTE: Do not use Flex on the inner ScrollView Container. See: https://stackoverflow.com/questions/74669190/react-native-scrollview-is-not-scrolling-down
        marginHorizontal: 10, // Margin of the entire scroll view itself i.e. between entire screen & boarder
        // paddingVertical: 30, // Padding between elements within scroll view i.e. between boarder and content
    },

    // Search Bar Styles
    searchBarView: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignContent: 'center',
        flexDirection: 'row',
        paddingLeft: 20,         
        borderWidth: 0.25,
        borderRadius: 10,
    },
    searchBarTextInput: {
        flex: 1,
        // margin: 15,
        // height: 40,
        // fontSize: 25 * PixelRatio.getFontScale(), // (PixelRatio.get() <= 2) ? 11 : 25,
    },

    // Address Validator
    AddressValidatorColumnView: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
        alignContent: 'center',
        flexDirection: 'row',
        paddingLeft: 20,
        borderWidth: 0.25,
        borderRadius: 10,
    },
    AddressValidatorTextInput: {
        flex: 1,
        // margin: 15,
        // height: 40,
        // fontSize: 25 * PixelRatio.getFontScale(), // (PixelRatio.get() <= 2) ? 11 : 25,
    },

    // Promos Styles
    PromosTileView: {
        flex: 1,
        backgroundColor: 'white',
        justifyContent: 'space-between',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 15,
        paddingTop: 15,
        borderWidth: 0.25,
        borderRadius: 10,
    },
    PromosCopyTextButton: {
        fontSize: 16,
        color: '#007AFF',
        textDecorationLine: 'underline',
        padding: 5,
    },
    PromosDescription: {
        fontWeight: 'bold',
        textAlign: 'center',
        padding: 5,
    },
    PromosTileListView: {
        paddingVertical: 2,
    },
    PromosTitleView: {
        flex: 1,
        backgroundColor: foragePurple,
        justifyContent: 'space-between',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 10,
        paddingBottom: 10,
        paddingTop: 10,
        paddingRight: 10,
        // borderBottomWidth: 1,
        // borderWidth: 1,
        // borderBottomColor: 'lightgray',
        borderRadius: 10,
    },

    // Distance Slider Styles
    DistanceSliderColumnView: {
        flex: 0.85,
        backgroundColor: 'white',
        // flexDirection: 'column',
        // borderWidth: 0.25,
        // borderRadius: 10,
        // paddingVertical: 10,
        // paddingHorizontal: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingTop: 10,
        borderWidth: 0.25,
        borderRadius: 10,
    },
    DistanceSliderTextViewStyle: {
        // flex: 1,
        // alignItems: 'center',
        // justifyContent: 'center',
        // // borderWidth: 1, // For Debugging
        flexDirection: 'row',
    },
    DistanceSliderStyle: {
        // flex: 0.85,
        // flexGrow: 1,
        paddingLeft: 20,
        paddingRight: 20,
    },
    DistanceSliderTextStyle: {
        color: 'white',
        fontSize: 12,
        fontWeight: 'bold',
    },
    DistanceSliderInfoTextStyle: {
        // color: 'white',
        // fontSize: 16,
        fontWeight: 'bold',
    },

    // Restaurant Styles
    RestaurantTileView: {
        flex: 1,
        backgroundColor: 'white',
        // justifyContent: 'space-between',
        flexDirection: 'column',
        // alignItems: 'center',
        paddingLeft: 10,
        paddingBottom: 10,
        paddingTop: 10,
        paddingRight: 10,
        borderWidth: 0.25,
        borderRadius: 10,
    },
    RestaurantTileListView: {
        flexGrow: 1,
        paddingVertical: 2,
    },
    RestaurantTileNameText: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    RestaurantTileFlatLisView: {
        flex: 1,
        flexDirection: 'column',
        margin: 1,
        padding: 2,
    },

    // Service Styles
    ServiceTileView: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    ServiceListHeaderText: {
        fontSize: 16,
        textDecorationLine: 'underline',
    },
    ServiceUpdatedText: {
        fontSize: 8,
        color: forageGray,
        paddingTop: 20,
    },
    ServicesDataTable: {
        flex: 1,
    },


    // Cart Estimate List Styles
    CartEstTileView: {
        // flex: 0.85,
        flexGrow: 1,
        backgroundColor: 'white',
        // flexDirection: 'column',
        // borderWidth: 0.25,
        // borderRadius: 10,
        // paddingVertical: 10,
        // paddingHorizontal: 10,
        paddingLeft: 10,
        paddingRight: 10,
        paddingBottom: 10,
        paddingTop: 10,
        borderWidth: 0.25,
        borderRadius: 10,
    },
    CartEstTitleTextViewStyle: {
        // flex: 1,
        // alignItems: 'center',
        // justifyContent: 'center',
        // // borderWidth: 1, // For Debugging
        flexDirection: 'row',
    },
    CartEstTileTextStyle: {
        // color: 'white',
        // fontSize: 16,
        fontWeight: 'bold',
    },

    // Account a.k.a. Profile Styles
    itemText: {
        fontWeight: 'bold',
    },
    ViewStyle: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        padding: 10,
    },
    profileImage: {
        // width: width - 60, // device width - some margin
        // height: height / 2 - 60, // device height / 2 - some margin
        width: Math.min(100, (width*0.5)), // device width - some margin
        height: Math.min(100, (width*0.5)), // device height / 2 - some margin
        borderRadius: Math.min(100, (width*0.5))*0.5,
    },
    AccountTileView: {
        flex: 1,
        width: Math.min(350, width*0.85),
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: "white",
        paddingLeft: 10,
        paddingBottom: 10,
        paddingTop: 10,
        paddingRight: 10,
        borderWidth: 0.25,
        borderRadius: 10,
    },
    AccountGoogleSigningButtonView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: "white",
        paddingVertical: 12,
        paddingHorizontal: 25,
        flexDirection: "row",
        borderWidth: 0.25,
        borderRadius: 10,
    },
    AccountSwitchView: {
        flex: 1,
        // width: Math.min(650, width*0.85),
        flexDirection: "row",
        justifyContent: "stretch",
        backgroundColor: "white",
        paddingLeft: 30,
        paddingBottom: 10,
        paddingTop: 10,
        paddingRight: 30,
        borderWidth: 0.25,
        borderRadius: 10,
    },

    // PWD Screen
    PWDTextInput: {
        height: 20,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
        borderColor: forageGray,
        backgroundColor: 'white',
        padding: 10,
    },


    // General Forage Button
    ForageButtonView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: foragePurple,
        paddingVertical: 12,
        paddingHorizontal: 25,
        flexDirection: "row",
        borderWidth: 0.25,
        borderRadius: 10,
    },
})

export default styles;