import React from "react";
import {
  Text,
  View,
  Image 
} from 'react-native';
import styles from '../utils/styles';

// See the following for implementation details:
// https://reactnavigation.org/docs/headers/#replacing-the-title-with-a-custom-component

const StackNavHeaderTitle = props => {
  return(
    <View style={styles.forageHeaderViewStyle}>

      <View style={{width: 10}} />

      <View style={{height: 50, flexDirection: 'row', alignItems: 'center',}}>
        <Text style={styles.forageHeaderText} > Forage</Text>

        <Image
          style={{ width: 25, height: 25 }}
          source={require('../assets/icon.png')}
        />

      </View>
    </View>
  );
};

export default StackNavHeaderTitle;