import * as React from "react";
import { Component } from "react";
import { StyleSheet, Text, View, Button, Alert } from "react-native";

import Fire from "../utils/Firebase";

// import { FORAGE_ENV } from "@env";
// const FORAGE_ENV = process.env.FORAGE_ENV;
import {FORAGE_ENV} from '@env'

import ForageTitle from "./ForageTitle";

function ButtonTest() {
  console.log("Button Press in " + FORAGE_ENV + " mode.");
}

export default class TestScreen extends Component {
  render() {
    return (
      <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
        <ForageTitle />
        <Text>Test Screen in the "{FORAGE_ENV}" Environment!</Text>
        <Button title="Try Me" onPress={ButtonTest} />
      </View>
    );
  }
}
