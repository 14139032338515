import * as React from 'react';
import { Component } from "react";
import { StyleSheet, Text, View, Button, Alert } from 'react-native';

import Fire from '../../utils/Firebase';

import {FORAGE_ENV} from '@env'

import ForageTitle from '../ForageTitle'

function ButtonTest(){
    console.log("Button Press")
    Fire.shared.tester().then((response) => {
        console.log( response )
    })
}

export default class TemplateScreen extends Component  {
    render() {
      return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <ForageTitle/>
            <Text>Template Screen in the "{FORAGE_ENV}" Environment!</Text>
            <Button title="Try Me" onPress={ButtonTest}/>
        </View>
      )
    }
  }