import * as React from 'react';
import { 
	View, 
	Text
} from 'react-native';

import styles from '../utils/styles.js';

import {FORAGE_ENV} from '@env'

export default class ForageTitle extends React.Component  {
  render() {
    return (
      <View style={styles.forageTitle}>
        <Text style={styles.forageTitleText}>Forage</Text>
        <Text style={styles.forageSubtext}>Search Food - Get Delivery</Text>
      </View>
    )
  }
}