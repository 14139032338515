import * as React from 'react';
import { Component } from "react";
import { createStackNavigator } from '@react-navigation/stack';


import HomeScreen from './Homescreen';
import StackNavHeaderTitle from '../../elements/StackNavHeaderTitle';


export default class HomeNav extends Component  {
  render() {
    const Stack = createStackNavigator();
    return (
      <Stack.Navigator
        initialRouteName="HomeScreen"
        screenOptions={{ title: '', headerShown: true,  headerBackTitle:''}}
      >
        <Stack.Screen name="HomeScreen" title='' component={HomeScreen}
          options={{ headerTitle: (props) => <StackNavHeaderTitle {...props} /> }}
        />
      </Stack.Navigator>
    );
  }
}