import * as React from 'react';
import { Component } from "react";
import { 
View, 
} from 'react-native';

import ForageTitle from '../ForageTitle'
import RestaurantList from '../../elements/RestaurantList'


export default class RestaurantListScreen extends Component  {
	constructor(props) {
		super(props);
    this.state = {
      distance: 0,
      restaurants: [],
      next_page_token: "",
    }
  }  

  componentDidMount(){
  }

  setDistance(newVal){
    this.setState({distance: newVal});
  }


  // (TODO) Radius for Forage 0 - 1050 meters
    render() {
      return (
        <View>
          <ForageTitle/>
          <RestaurantList />
        </View>
      )
    }
  }