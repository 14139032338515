import * as React from 'react';
import { 
  View, 
  Text, 
  Image,
  SafeAreaView,
  ScrollView,
} from 'react-native';

import Fire from '../../utils/Firebase.js';
import styles from '../../utils/styles.js';
import styleConst from '../../utils/styleConst.js';
import ForageTitle from '../ForageTitle.js';

import AccountTile from '../../elements/AccountTiles.js';
const AnonymousUser = require('../../assets/AnonymousUser.png')



export default class AccountScreen extends React.Component {
  constructor(props){
    super(props);
  }



  getHeader = () => {
    const user = Fire.shared.auth.currentUser;
    return(
      <View>
        <ForageTitle/>

        {/*Profile Image & Name*/}
        <View style={styles.ViewStyle}>
          <Image source={ user.photoURL ? {uri: user.photoURL } :  AnonymousUser} style={styles.profileImage} />
          <Text style={styles.forageSubtext}>{user.displayName}</Text>
        </View>

      </View>
    )
  }

  render() {
    const props = this.props;
    return (
      <SafeAreaView style={styles.SafeAreaView}>
        <ScrollView style={styles.ScrollView}>
          <View style={{
            flex: 1, justifyContent: 'center', alignItems: 'center', }}>
          {this.getHeader()}


          {/* <AccountTile  
            icon="information"
            onPress={async () => { this.props.navigation.navigate('About'); }}
            title="About"
          /> */}


          <AccountTile 
            icon="cogs"
            onPress={async () => { 
              this.props.navigation.navigate('SettingsScreen');
            }}
            title="Settings"
          />


          <View style={{padding: 10}}/> 

          <AccountTile 
            title="Sign Out"
            onPress={async () => {
              await Fire.shared.signout();
              this.props.navigation.navigate('Login');
            }}
              color={styleConst.foragePurple}
              fontColor='white'
          />

          <View style={{padding: 10}}/> 

          </View>
        </ScrollView>
      </SafeAreaView>
    );
  }
}